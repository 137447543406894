header {
  width: 100%;
  padding: 15px 0 20px;
  border-top: 2px solid #37b721;
  box-shadow: 0 0 20px #c3c7cd;
}

.cm-header-logo {
  width: 140px;
}

.cm-header-logo img {
  width: 100%;
  display: block;
}

.cm-header-op {
  width: calc(100% - 170px);
  justify-content: flex-end;
}

.cm-header-phone {
  position: relative;
}

.cm-header-phone img {
  width: 44px;
  position: absolute;
  left: -52px;
  top: 0px;
}

.cm-header-phone a {
  font-size: 20px;
  font-weight: 800;
  color: #337ab7;
}

.cm-header-phone span {
  font-size: 13px;
  font-weight: 600;
  line-height: .5;
  display: block;

}

.cm-header-phone>i {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 14px;
  background-color: #337ab7;
}

.mobile-view {
  display: none;
}

.cm-sec-menu ul li {
  padding: 0 10px;
}

.cm-sec-menu ul li a {
  transition: .3s;
  /* position: relative; */
}

/* .cm-sec-menu ul li a:hover::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #37b721;
} */

.cm-sec-menu ul li a:hover {
  color: #37b721;
}

.mobile-menu {
  display: none;
  list-style: none;
}

@media(max-width: 767px) {
  .cm-sec-menu .cm-menu-ul {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}

@media(max-width: 600px) {
  header {
    padding: 9px 0 13px;
  }

  .cm-header-logo {
    width: 100px;
  }

  .cm-header-phone a {
    font-size: 15px;
  }

  .cm-header-phone img {
    width: 29px;
    left: -38px;
    top: 4px;
  }

  .cm-header-phone span {
    font-size: 10px;
  }
}